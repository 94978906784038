/* ----------------------------------------------------------------
	Canvas: Photographer
-----------------------------------------------------------------*/

:root {
	--cnvs-themecolor: #e41c34;
	--cnvs-themecolor-rgb: 228, 28, 52;
	--cnvs-dark-color: #050505;

	--cnvs-body-font: 'Poppins', sans-serif;
	--cnvs-primary-font: 'Poppins', sans-serif;
}

body.dark {
	--cnvs-body-bg: var(--cnvs-dark-color);
}

#header {
	z-index: 399;
}

.slider-element h2 {
	font-size: 3.2rem;
	line-height: 1;
	font-weight: 700;
	letter-spacing: 0;
}

.button.button-change.button-color {
	background-color: #FFF !important;
	text-shadow: none;
}

.button.button-change { box-shadow: 0px 28px 32px -22px rgba(0,0,0,0.75); }

.dark.button.button-change,
.dark.section { background-color: var(--cnvs-dark-color) !important; }

.gradient-border {
	--borderWidth: 3px;
	background: #1D1F20;
	position: relative;
	border-radius: 0;
}

.gradient-border::after {
	content: '';
	position: absolute;
	top: calc(-1 * var(--borderWidth));
	left: calc(-1 * var(--borderWidth));
	height: calc(100% + var(--borderWidth) * 2);
	width: calc(100% + var(--borderWidth) * 2);
	background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	border-radius: var(--borderWidth);
	z-index: -1;
	animation: animatedgradient 3s ease alternate infinite;
	background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}

@media (min-width: 992px) {
	#header.transparent-header {
		--cnvs-header-bg: transparent;
		--cnvs-header-border-color: transparent;
	}

	#header + .include-header {
		margin-top: calc(calc(-1 * var(--cnvs-header-height)) - 1px);
		display: inline-block;
		vertical-align: bottom;
		width: 100%;
	}

	.slider-element h2 {
		font-size: 4.8rem;
		line-height: 1.2;
		letter-spacing: -4px;
	}

}

/* img-hover-wraps */
.img-hover-wrap {
	-webkit-transition: transform .3s ease;
	-o-transition: transform .3s ease;
	transition: transform .3s ease;
	-moz-transform-style: flat !important;
	-webkit-backface-visibility: hidden;
}

.img-hover-wrap:hover {
	-webkit-transform: scale(1.06);
	-ms-transform: scale(1.06);
	-o-transform: scale(1.06);
	transform: scale(1.06);
	z-index: 2;
}

.img-hover-card > a::after {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 0;
	-webkit-transition: background-color .3s ease;
	-o-transition: background-color .3s ease;
	transition: background-color .3s ease;
}

.img-hover-wrap:hover .img-hover-card > a::after { background-color: rgba(0, 0, 0, 0.6); }

.img-hover-card {
	position: relative;
	display: block;
	will-change: transform;
	transition: all .3s ease;
	box-shadow: 0 10px 40px transparent;
}

.img-hover-card a {
	width: 100%;
	max-width: 100%;
	display: block;
	z-index: 99;
}

.img-hover-card.hover-in {
	transition: -webkit-transform .2s ease-out;
	transition: transform .2s ease-out;
	transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.img-hover-card.hover-3d { z-index: 2; }

.img-hover-card.hover-out {
	transition: -webkit-transform .2s ease-in;
	transition: transform .2s ease-in;
	transition: transform .2s ease-in, -webkit-transform .2s ease-in;
}

.img-hover-wrap:hover .img-hover-card { box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4); }

.img-hover-wrap .img-hover-detail {
	position: absolute;
	top: auto;
	left: 40px;
	bottom: 40px;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-webkit-transform: scale(1) translateY(40px) translateZ(50px);
	-ms-transform: scale(1) translateY(30px) translateZ(50px);
	-o-transform: scale(1) translateY(30px) translateZ(50px);
	transform: scale(1) translateY(30px) translateZ(50px);
	-webkit-transition: transform .2s ease;
	-o-transition: transform .2s ease;
	transition: transform .2s ease;
}

.img-hover-wrap:hover .img-hover-detail {
	opacity: 1;
	-webkit-transform: scale(1.06) translateY(-5px) translateZ(50px);
	-ms-transform: scale(1.06) translateY(-5px) translateZ(50px);
	-o-transform: scale(1.06) translateY(-5px) translateZ(50px);
	transform: scale(1.06) translateY(-5px) translateZ(50px);
}

.img-hover-wrap .img-hover-title {
	margin: 0 0 6px 0;
	font-size: 17px;
	font-weight: 600;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	z-index: 9;
}

.img-hover-wrap .img-link {
	opacity: 0;
	width: 20px;
	height: 20px;
	border: 1px solid #FFF;
	border-radius: 50%;
	text-align: center;
	line-height: 18px;
	font-size: 11px;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

.img-hover-wrap:hover .img-link { opacity: 1; }

.img-hover-detail a { color: #FFF; }

.testimonials-carousel .owl-item {
	transform: translateX(-40px);
	opacity: 0;
	transition: transform .4s, opacity .3s;
}

.testimonials-carousel .owl-item.active {
	transform: translateX(0);
	opacity: 1;
}

@media (max-width: 991.98px) {
	#header.dark.transparent-header, #header.dark.semi-transparent { background-color: var(--cnvs-dark-color); }

	.primary-menu ul > li > a, .primary-menu.style-2 > div > ul > li > a {
		padding: 10px !important;
		border-radius: 0 !important;
		margin-right: inherit !important;
	}
}

.css3-spinner > div {
	margin-top: -36px;
	margin-left: -32px;
	width: 62px;
	height: 72px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

#gotoTop { border-radius: 50% }

.dark-mode {
	position: fixed;
	top: auto;
	right: 11px;
	bottom: 80px;
	text-align: center;
	margin: 0 auto;
	z-index: 299;
}

.dark-mode i {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 16px;
	color: #FFF !important;
	background-color: #111;
	border-radius: 50%;
	margin: 0 auto;
}

.dark-mode span {
	display: block;
	opacity: 0;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	margin: 0;
	-webkit-transform: translateY(10px);
	-ms-transform: translateY(10px);
	-o-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-transition: transform .3s, opacity .3s;
	-o-transition: transform .3s, opacity .3s;
	transition: transform .3s, opacity .3s;
	-webkit-backface-visibility: hidden;
}

.dark-mode:hover span {
	opacity: 1;
	margin-bottom: 7px;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

.dark .dark-mode i {
	color: #111 !important;
	background-color: #FFF;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

.dark .dark-mode span { color: #FFF; }
